import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ProgressBar as StepProgressBar, Step } from 'react-step-progress-bar';
import 'react-step-progress-bar/styles.css';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';

function MultiStepForm() {
  const [currentStep, setCurrentStep] = useState(1);
  const [apiResponse, setApiResponse] = useState(null);
  const [apiData, setApiData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState('');
  const [formData, setFormData] = useState('');
  const [formStepsData, setFormStepsData] = useState([
    {
      step: 1,
      data: {
        clientBirthdate: '',
        TINHolder: '',
        maritalStatus: '',
        employmentContract: '',
        employmentContractDate: '',
        income: '',
        employmentType: '',
        housingType: '',
        mortgageAmount: '',
        dtStart: ''
      },
    },
    {
      step: 2,
      data: {
        secHolder: '',
        relationship: '',
        secHolderName: '',
        secHolderBirthdate: '',
        TINSecHolder: '',
        secHolderPhone: '',
        secHolderEmail: '',
        secHolderEmploymentContract: '',
        employmentContractDateSecHolder: '',
        incomeSecHolder: '',
        nationalitySecHolder: '',
        residenceTitleSecHolder: '',
        residenceTogether: ''
      },
    },
    {
      step: 3,
      data: {
        purpose: '',
        amount: '',
        targetTerm: '',
        otherCredits: '',
        otherCreditsAmount: '',
        clientName: '',
        contactEmail: '',
        contactPhone: ''
      },
    },
  ]);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const leadToken = searchParams.get('token');
  console.log("LeadToken: " + leadToken);

  useEffect(() => {
    if (!leadToken) {
      setLoading(false);
      return;
    }

    // Make API request to retrieve data based on leadToken
    fetch(`https://api.jpcom.pt/api/leads/${leadToken}`, {
      method: 'GET',
    })
      .then(response => response.json())
      .then(data => {
        setApiData(data);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  }, [leadToken]);

useEffect(() => {
  // Pre-fill form input fields with data from API
  if (apiData) {
    const { 
      clientBirthdate,
      TINHolder,
      maritalStatus,
      employmentContract,
      employmentContractDate,
      income,
      employmentType,
      housingType,
      mortgageAmount,
      dtStart,
      secHolder,
      relationship,
      secHolderName,
      secHolderBirthdate,
      TINSecHolder,
      secHolderPhone,
      secHolderEmail,
      secHolderEmploymentContract,
      employmentContractDateSecHolder,
      incomeSecHolder,
      nationalitySecHolder,
      residenceTitleSecHolder,
      residenceTogether,
      purpose,
      amount,
      targetTerm,
      otherCredits,
      otherCreditsAmount,
      clientName,
      contactEmail,
      contactPhone
    } = apiData;

    setFormData({ 
      clientBirthdate,
      TINHolder,
      maritalStatus,
      employmentContract,
      employmentContractDate,
      income,
      employmentType,
      housingType,
      mortgageAmount,
      dtStart,
      secHolder,
      relationship,
      secHolderName,
      secHolderBirthdate,
      TINSecHolder,
      secHolderPhone,
      secHolderEmail,
      secHolderEmploymentContract,
      employmentContractDateSecHolder,
      incomeSecHolder,
      nationalitySecHolder,
      residenceTitleSecHolder,
      residenceTogether,
      purpose,
      amount,
      targetTerm,
      otherCredits,
      otherCreditsAmount,
      clientName,
      contactEmail,
      contactPhone
    });
  }
}, [apiData]);

//handleFormSubmit - submete para a API os dados preenchidos no formulário e preparara a chamada para a função que verifica se está dentro de regras ou não.
  const handleFormSubmit = (event) => {
    event.preventDefault();
    console.log(formStepsData);
    // Add code to submit the form data to the backend
    fetch('https://api.jpcom.pt/post/update-lead/' + leadToken, {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: {
        'Content-Type': 'application/json',
        'lead-token': leadToken
      }
    })
      .then(response => response.json())
      .then(data => {
        verifyLeadRules(data);
        setApiResponse(data);
        handleNextStep();
      })
      .catch(error => {
        console.error(error);
      });
  };
  
  const verifyLeadRules = (event) => {
    event.preventDefault();
    fetch(`https://api.jpcom.pt/api/leadRules/`, {
      method: 'GET',
    })
    .then(response => response.json())
    .then(data =>{
      // GET LEAD RULES

      //Residenciatemporaria
      //Contratotemporario

      //if contrato trabalho Efetivo/Termo Certo
      //if Reformado
      //if ENI + 36meses e 1250€ / 2000€ (2titulares)

    })
    .catch(error => {
      console.error(error);
    })
  };


  // handleSubmitProcess - permite submeter o processo caso seja viável, tendo passado as regras definidas e havendo interesse do cliente.
  const handleSubmitProcess = (event) => {
    event.preventDefault();
    // const leadToken = 'your-lead-token';
    console.log(leadToken);
    console.log('Abrir processo via API...');
    window.location.href = `/documentos/?token=${leadToken}`;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const currentStepData = formStepsData.find(stepData => stepData.step === currentStep);

    if (currentStepData) {
      const updatedData = { ...currentStepData.data, [name]: value };
      const updatedStepsData = formStepsData.map(stepData =>
        stepData.step === currentStep ? { ...stepData, data: updatedData } : stepData
      );
      setFormStepsData(updatedStepsData);
    }

    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleOptOut = (event) => {
    event.preventDefault();
    console.log('Apenas simulação. Enviar email');
  };

  const handleNextStep = () => {
    // Validates missing required fields
    if (!formData.clientBirthdate || !formData.TINHolder || !formData.maritalStatus) {
      // If any of the required fields are not filled, prevent advancing to the next step
      // alert('Please fill in all required fields');
      // return;
    }

    setCurrentStep(currentStep + 1);
  };
  
  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  if (loading) {
    return (
      <div className='card'>
        <div className='card-body p-0'>
          A Carregar...
        </div>
      </div>
    );
  }

  if (!leadToken || leadToken === '') {
    return (
      <div className='card'>
        <div className='card-body p-0'>
        <div className="disclaimer_div">
          <div className="div_img"></div>
          <span>Gestlifes é uma marca JPCOM, intermediário de crédito vinculado e autorizado pelo Banco de Portugal <a href="https://www.bportugal.pt/intermediariocreditofar/jpcom-unipessoal-lda" target="_blank" rel="noopener noreferrer">nº1409</a>.</span>
        </div>
        <div className="disclaimer">
          <img className="notValidIcon" src="https://client.gestlifes.com/webroot/IMAGE/JPCOM/ICON/declined.svg" alt="Ícone"></img>
          <span>
            Lamentamos, mas o link introduzido não é válido.<br />
            Entre em contacto connosco ou faça uma nova simulação em <a href="https://www.gestlifes.com">www.gestlifes.com</a>.
          </span>
        </div>
      </div>
      </div>
    );
  }  
  
  return (
    <div>
    <div className="card">
      <div className="card-header">
      <StepProgressBar
      percent={(currentStep / 4) * 100}
      filledBackground="#214FA4"
      unfilledBackground="#ddd"
      height={10}
    >
      <Step transition="scale">
        {({ accomplished }) => (
          <div className="relative w-full text-center">
            <div className="inline-block align-text-top">
              {accomplished ? (
                <i className="fas fa-check text-green-500"></i>
              ) : (
                <div className="w-4 h-4 rounded-full bg-gray-400 inline-block"></div>
              )}
            </div>
          </div>
        )}
      </Step>
      <Step transition="scale">
        {({ accomplished }) => (
          <div className="relative w-full text-center">
            <div className="inline-block align-text-top">
              {accomplished ? (
                <i className="fas fa-check text-green-500"></i>
              ) : (
                <div className="w-4 h-4 rounded-full bg-gray-400 inline-block"></div>
              )}
            </div>
          </div>
        )}
      </Step>
      <Step transition="scale">
        {({ accomplished }) => (
          <div className="relative w-full text-center">
            <div className="inline-block align-text-top">
              {accomplished ? (
                <i className="fas fa-check text-green-500"></i>
              ) : (
                <div className="w-4 h-4 rounded-full bg-gray-400 inline-block"></div>
              )}
            </div>
          </div>
        )}
      </Step>
      <Step transition="scale">
        {({ accomplished }) => (
          <div className="relative w-full text-center">
            <div className="inline-block align-text-top">
              {accomplished ? (
                <i className="fas fa-check text-green-500"></i>
              ) : (
                <div className="w-4 h-4 rounded-full bg-gray-400 inline-block"></div>
              )}
            </div>
          </div>
        )}
      </Step>
    </StepProgressBar>
      </div>

      <div className='card-body p-0'>
        <div className="disclaimer_div">
          <div className="div_img"></div>
          <span>Gestlifes é uma marca JPCOM, intermediário de crédito vinculado e autorizado pelo Banco de Portugal <a href="https://www.bportugal.pt/intermediariocreditofar/jpcom-unipessoal-lda" target="_blank" rel="noopener noreferrer">nº1409</a>.</span>
        </div>

        {currentStep === 1 && (
          <Step1 
            formData={formData} 
            formStepsData={formStepsData} 
            currentStep={currentStep} 
            setFormData={setFormData} 
            handleNextStep={handleNextStep} 
            handleChange={handleChange} 
            selectedOption={selectedOption} 
            setSelectedOption={setSelectedOption} 
            handleOptionChange={handleOptionChange} />
        )}
        {currentStep === 2 && (
          <Step2 
            formData={formData} 
            formStepsData={formStepsData} 
            currentStep={currentStep} 
            setFormData={setFormData} 
            handleNextStep={handleNextStep} 
            handlePreviousStep={handlePreviousStep} 
            handleChange={handleChange} 
            selectedOption={selectedOption} 
            setSelectedOption={setSelectedOption} 
            handleOptionChange={handleOptionChange}  />
        )}
        {currentStep === 3 && (
          <Step3 
            formData={formData} 
            formStepsData={formStepsData} 
            currentStep={currentStep} 
            setFormData={setFormData} 
            handleNextStep={handleNextStep} 
            handlePreviousStep={handlePreviousStep} 
            handleFormSubmit={handleFormSubmit} 
            handleChange={handleChange} 
            apiResponse={apiResponse} 
            setApiResponse={setApiResponse} 
            selectedOption={selectedOption} 
            setSelectedOption={setSelectedOption} 
            handleOptionChange={handleOptionChange}  />
        )}
        {currentStep === 4 && (
          <Step4 
            formData={formData} 
            formStepsData={formStepsData} 
            currentStep={currentStep} 
            setFormData={setFormData} 
            handleOptOut={handleOptOut} 
            handleSubmitProcess={handleSubmitProcess} 
            handleChange={handleChange} 
            apiResponse={apiResponse} 
            setApiResponse={setApiResponse} 
            selectedOption={selectedOption} 
            setSelectedOption={setSelectedOption} 
            handleOptionChange={handleOptionChange}  />
        )}
      </div>
    </div>
    <p className='tokenID'><span>Token ID:</span> {leadToken}</p>
  </div>
  );
}

export default MultiStepForm;