import React from 'react';

function Step3(props) {
  const { formData, setFormData, handleChange, handlePreviousStep, handleFormSubmit, selectedOption, setSelectedOption } = props;

    // Function to validate the form data
    const validateForm = () => {
      const { purpose, amount, targetTerm, otherCredits, otherCreditsAmount, clientName, contactEmail, contactPhone, residenceTitle } = formData;
  
      if (!purpose || !amount || !targetTerm || !otherCredits || !clientName || !contactEmail || !contactPhone || (otherCredits === '1' && !otherCreditsAmount) || (residenceTitle === '' && residenceTitle !== '0' && residenceTitle !== '1')) {
        // If any of the required fields are missing, show error messages
        const requiredFields = ['purpose', 'amount', 'targetTerm', 'otherCredits', 'clientName', 'contactEmail', 'contactPhone'];
        requiredFields.forEach(field => {
          const inputElement = document.getElementById(field);
          const helpBlockElement = document.getElementById(`${field}HelpBlock`);
          if (!formData[field]) {
            inputElement.classList.add('is-invalid');
            helpBlockElement.innerText = 'Este campo é obrigatório.';
          } else {
            inputElement.classList.remove('is-invalid');
            helpBlockElement.innerText = '';
          }
        });
  
        // Additional validation rules
        if (otherCredits === '1' && !otherCreditsAmount) {
          const otherCreditsAmountInput = document.getElementById('otherCreditsAmount');
          otherCreditsAmountInput.classList.add('is-invalid');
          const otherCreditsAmountHelpBlock = document.getElementById('otherCreditsAmountHelpBlock');
          otherCreditsAmountHelpBlock.innerText = 'Este campo é obrigatório.';
        }
  
        if (residenceTitle === '' && residenceTitle !== '0' && residenceTitle !== '1') {
          const residenceTitleInput = document.getElementById('residenceTitle');
          residenceTitleInput.classList.add('is-invalid');
          const residenceTitleHelpBlock = document.getElementById('residenceTitleHelpBlock');
          residenceTitleHelpBlock.innerText = 'Selecione uma opção.';
        }
  
        return false;
      }
  
      return true;
    };
  
    // Function to handle form submission
    const handleSubmit = () => {
      if (validateForm()) {
        handleFormSubmit();
      }
    };

  return (
    <div>
      <h2>Informações Gerais</h2>

      <div className="row">
        <div className="form-group col-12">
          <label className="largeLabel">Finalidade</label>
          <div className="dropdown bootstrap-select col-12 p-0">
            <select className="col-12 p-0 selectpicker" id="purpose" name="purpose" data-style="form-control" title="Selecione..." tabindex="1">
              <option className="bs-title-option" value=""></option>
              <option value="1">Eletrodomésticos</option>
              <option value="2">Automóvel/Mota</option>
              <option value="3">Obras</option>
              <option value="4">Mobiliário/Decoração</option>
              <option value="5">Férias</option>
              <option value="6" selected="">Outras</option>
              <option value="29">Pagar Créditos</option>
            </select>
          </div>
          <span className="invalid-feedback">&nbsp;</span>
        </div>

        <div className="form-group col-12">
          <div className="row">
            <div className="form-group col-md-6">
              <label className="largeLabel">Montante</label>
              <input className="form-control mortgageAmount" type="text" id="amount" name="amount" value="" placeholder="5.000 - 50.000" tabindex="2" />
              <span className="invalid-feedback">&nbsp;</span>
            </div>
            <div className="form-group col-md-6">
              <label className="largeLabel">Prazo</label>
              <input className="form-control" type="text" id="targetTerm" name="targetTerm" value="" placeholder="12 - 84" tabindex="3" />
              <span className="invalid-feedback">&nbsp;</span>
            </div>
          </div>
        </div>

        <div className="anchor" id="otherCreditsAnchor"></div>

        <div className="form-group col-12">
          <label className="largeLabel">Possui outros créditos?</label>
          <div className="row pl-3 fourButtons">
            <div className="radioDiv" tabindex="2">
              <input onChange={handleChange} type="radio" name="otherCredits" id="SIM" value="1" />
              <label className="radioBtn" for="SIM">SIM</label>
            </div>
            <div className="radioDiv" tabindex="2">
              <input onChange={handleChange} type="radio" name="otherCredits" id="NAO" value="2" />
              <label className="radioBtn" for="NAO">NÃO</label>
            </div>
          </div>
          <span className="invalid-feedback" id="otherCreditsHelpBlock">&nbsp;</span> 
        </div>

        <div className="form-group col-12">
          <div className="row">
            <div className="form-group col-md-6">
              <label className="largeLabel">Valor das prestações mensais (total)</label>
              <input type="text" className="form-control mortgageAmount" id="otherCreditsAmount" name="otherCreditsAmount" value="" placeholder="Insira o valor total" tabindex="4" />
              <span className="invalid-feedback">&nbsp;</span>
            </div>
          </div>
        </div>

      <div className="form-group col-12">
        <div className="row">
          <div className="form-group col-md-6">
            <label className="largeLabel">Nome</label>
            <input type="text" className="form-control" id="clientName" name="clientName" value="" placeholder="Digite o seu nome" tabindex="4" />
            <span className="invalid-feedback">&nbsp;</span>
          </div>
          <div className="form-group col-md-6">
            <label className="largeLabel">E-mail</label>
            <input className="form-control" type="text" id="contactEmail" name="contactEmail" value="" placeholder="Digite o seu e-mail" tabindex="5" />
            <span className="invalid-feedback">&nbsp;</span>
          </div>
        </div>
      </div>
      
      <div className="form-group col-12">
        <div className="row">
          <div className="form-group col-md-6">
            <label className="largeLabel">Telefone</label>
            <input className="form-control" type="text" id="contactPhone" name="contactPhone" data-mask="000 000 000" value="" placeholder="Digite o seu telemóvel" tabindex="6" autocomplete="off" maxlength="11" />
            <span className="invalid-feedback">&nbsp;</span>
          </div>
          <div className="form-group col-md-6">
            <label className="largeLabel">Nacionalidade</label>
            <span className="invalid-feedback">&nbsp;</span>
          </div>
          <div className="form-group col-md-6 d-none" id="residenceDiv">
            <label className="largeLabel">Tipo de Residência</label>
            <div className="dropdown bootstrap-select col-12 p-0">
              <select className="selectpicker col-12 p-0" id="residenceTitle" name="residenceTitle" data-style="form-control" title="Selecione..." tabindex="8">
                <option className="bs-title-option" value=""></option>
                <option value="1" selected="">Residência Permanente</option>
                <option value="0">Residência Temporária</option>
              </select>
            </div>
            <span className="invalid-feedback">&nbsp;</span>
          </div>
        </div>
      </div>
    </div>

    <div className="form-group col-12 px-0  d-block">
      <button className="generalBackBtn align-items-center justify-content-center" onClick={handlePreviousStep}>Voltar</button>
      <button className="generalInfoBtn align-items-center justify-content-center" onClick={handleFormSubmit}>Saber o valor da minha prestação</button>
    </div>
  </div>
  );
}

export default Step3;
