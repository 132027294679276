import React, { useState } from 'react';

function Step4(props) {
  const { formData, setFormData, handleChange, handleSubmitProcess, handleOptOut, apiResponse, selectedOption, setSelectedOption } = props;

  console.log(apiResponse);

  return (
    <div>
      <h2>Resultado</h2>

      {/* Display the results based on the formData */}
      <p>
        O seu pedido de [{formData.purpose}] foi [{formData.status}] para o montante de [{formData.amount}] no prazo de [{formData.targetTerm}] meses.
      </p>

      <div className="form-group col-12">
        <label>Prestação</label>
        <input className="form-control mortgageAmount" type="text" id="mortgage" name="mortgage" data-link="/process/calcPretendedMortgage" value="502,33€" readOnly />
        <span className="mortgageFooter">Valor meramente indicativo e corresponde a um valor médio de simulação</span>
      </div>

      <div className="form-group col-12 px-0  d-block">
        <button className="generalBackBtn align-items-center justify-content-center" onClick={handleOptOut}>
          Apenas pretendo a simulação
        </button>
        <button className="generalOkBtn align-items-center justify-content-center" onClick={handleSubmitProcess}>
          Obter Pré-Aprovação
        </button>
      </div>
      <hr />


      
      {/* Conditional rendering of the API response */}
      {apiResponse && (
        <div>
          <h2>API Response:</h2>
          <p>{apiResponse.message}</p>
        </div>
      )}

      <div className="form-group col-12 px-0">
        <p className="mortgageFooter">
          Alternativamente poderá optar subscrever um cartão de crédito pertencente ao nosso parceiro Unibanco, o qual, analisará a sua atribuição e se aprovado, definirá o respetivo plafond a atribuir. O valor atribuído será no máximo até 3x o valor do ordenado base.
          <br />
          <strong>A anuidade é gratuita e não tem custos de adesão.</strong>
        </p>
        <p className="mortgageFooter">Se desejar aderir, deverá aceder ao seguinte link, para que possa assim dar início ao seu pedido.</p>
        <a href="https://www.notion.so/carreira-gestlifes/Link-Cart-o-de-Cr-dito-cacbe80de8e74cea8f04d36fcd5dcd3e?pvs=4#82e4aaa290ad4cb88a1210a2912f7dd5">
          Pedir cartão de crédito
        </a>
      </div>
    </div>
  );
}

export default Step4;
