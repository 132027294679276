import React from 'react';

function Step1(props) {
  const { formData, formStepsData, currentStep, setFormData, handleChange, handleNextStep, selectedOption, setSelectedOption, handleOptionChange } = props;

    // Function to validate the form data
    const validateForm = () => {
      const { clientBirthdate, TINHolder, maritalStatus, employmentContract, income, employmentType, housingType, mortgageAmount, dtStart } = formStepsData[currentStep - 1].data;
  
      if (!clientBirthdate || !TINHolder || !maritalStatus || !employmentContract || !income || !employmentType || !housingType) {
        // If any of the required fields are missing, show error messages
        const requiredFields = ['clientBirthdate', 'TINHolder', 'maritalStatus', 'employmentContract', 'income', 'employmentType', 'housingType'];
        requiredFields.forEach(field => {
          const inputElement = document.getElementById(field);
          const helpBlockElement = document.getElementById(`${field}HelpBlock`);
          if (!formStepsData[currentStep - 1].data[field]) {
            inputElement.classList.add('is-invalid');
            helpBlockElement.innerText = 'Este campo é obrigatório.';
          } else {
            inputElement.classList.remove('is-invalid');
            helpBlockElement.innerText = '';
          }
        });
        return false;
      }

      // Additional validation rules can be added here
  
      return true;
    };

     // Function to handle form submission
  const handleSubmit = () => {
    if (validateForm()) {
      handleNextStep();
    }
  };

  return (
    <div>
      <div className="tab-pane fade active show" id="personalData" role="tabpanel" aria-labelledby="personalDataTab">
        <h2>Dados Pessoais</h2>
        <div className="row">
          <div className="form-group col-12 col-md-6">
            <label className="largeLabel">Data De Nascimento</label>
            <div className="input-group mt-0 date" id="datetimepickerBirthdate" data-target-input="nearest">
              <input required value={formStepsData[currentStep - 1].data.clientBirthdate} onChange={handleChange} className="form-control datetimepicker-input" data-target="#datetimepickerBirthdate" type="date" data-mask="00/00/0000" id="clientBirthdate" name="clientBirthdate" placeholder="DD/MM/AAAA" tabindex="1" autocomplete="off" maxlength="10" />
            </div>
          </div>
          
          <div className="form-group col-12 col-md-6">
            <label className="largeLabel">NIF</label>
            <input onChange={handleChange} className="form-control" id="TINHolder" name="TINHolder" placeholder="Insira o seu NIF" data-mask="000 000 000" tabindex="2" autocomplete="off" maxlength="11" />
          </div>
              
          <div className="anchor" id="maritalAnchor"></div>

          <div className="form-group col-12">
            <label className="largeLabel">Estado Civil</label>
            <div className="row pl-3">
              <div className="radioDiv" tabindex="3">
                <input onChange={handleChange} type="radio" name="maritalStatus" id="single" value="single" />
                <label className="radioBtn" for="single">Solteiro</label>
              </div>
              <div className="radioDiv" tabindex="3">
                <input onChange={handleChange} type="radio" name="maritalStatus" id="married" value="married" />
                <label className="radioBtn" for="married">Casado</label>
              </div>
              <div className="radioDiv" tabindex="3">
                <input onChange={handleChange} type="radio" name="maritalStatus" id="separate" value="separate" />
                <label className="radioBtn" for="separate">Separado</label>
              </div>
              <div className="radioDiv" tabindex="3">
                <input onChange={handleChange} type="radio" name="maritalStatus" id="divorced" value="divorced" />
                <label className="radioBtn" for="divorced">Divorciado</label>
              </div>
              <div className="radioDiv" tabindex="3">
                <input onChange={handleChange} type="radio" name="maritalStatus" id="widowed" value="widowed" />
                <label className="radioBtn" for="widowed">Viúvo</label>
              </div>
              <div className="radioDiv" tabindex="3">
                <input onChange={handleChange} type="radio" name="maritalStatus" id="commonLawMarriage" value="commonLawMarriage" />
                <label className="radioBtn" for="commonLawMarriage">União de facto</label>
              </div>
            </div>
            <span className="invalid-feedback" id="maritalStatusHelpBlock">&nbsp;</span>
          </div>
          

          <div className="anchor" id="employmentAnchor"></div>
          <div className="form-group col-12">
            <label className="largeLabel">Situação Profissional</label>
            <div className="row pl-3">
              <div className="radioDiv" tabindex="4">
                <input onChange={handleChange} type="radio" name="employmentContract" id="Efetivo" value="1" />
                <label className="radioBtn" for="Efetivo">Efetivo</label>
              </div>
              <div className="radioDiv" tabindex="4">
                <input onChange={handleChange} type="radio" name="employmentContract" id="Termo Certo" value="2" />
                <label className="radioBtn" for="Termo Certo">Termo Certo</label>
              </div>
              <div className="radioDiv" tabindex="4">
                <input onChange={handleChange} type="radio" name="employmentContract" id="Independente" value="3"/>
                <label className="radioBtn" for="Independente">Independente</label>
              </div>
              <div className="radioDiv" tabindex="4">
                <input onChange={handleChange} type="radio" name="employmentContract" id="Temporário" value="4" />
                <label className="radioBtn" for="Temporário">Temporário</label>
              </div>
              <div className="radioDiv" tabindex="4">
                <input onChange={handleChange} type="radio" name="employmentContract" id="Desempregado" value="5" />
                <label className="radioBtn" for="Desempregado">Desempregado</label>
              </div>
              <div className="radioDiv" tabindex="4">
                <input onChange={handleChange} type="radio" name="employmentContract" id="Reformado" value="6" />
                <label className="radioBtn" for="Reformado">Reformado</label>
              </div>
              <div className="radioDiv" tabindex="4">
                <input onChange={handleChange} type="radio" name="employmentContract" id="Outro" value="7" />
                <label className="radioBtn" for="Outro">Outro</label>
              </div>
            </div>
            <span className="invalid-feedback" id="employmentContractHelpBlock">&nbsp;</span> 
          </div>

          <div className="form-group col-12 col-md-6">
            <label>Desde Que Data&nbsp;(mês/Ano)</label>
            <div className="input-group mt-0 date" id="datetimepickerProfession" data-target-input="nearest">
              <input onChange={handleChange} className="form-control datetimepicker-input" data-target="#datetimepickerProfession" type="date" data-mask="00/0000" id="employmentContractDate" name="employmentContractDate" placeholder="MM/AAAA" tabindex="5" autocomplete="off" maxlength="7" />
            </div>
            <span className="invalid-feedback">&nbsp;</span>
          </div>

          <div className="form-group col-12 col-md-6">
            <label>Vencimento</label>
            <input onChange={handleChange} className="form-control mortgageAmount" id="income" name="income" placeholder="Insira o seu vencimento" tabindex="6" />
            <span className="invalid-feedback">&nbsp;</span>
          </div>


          <div className="anchor" id="employmentTypeAnchor"></div>
          <div className="form-group col-12">
            <label className="largeLabel">Tipo de Entidade Empregadora</label>
            <div className="row pl-3 fourButtons">
              <div className="radioDiv" tabindex="2">
                <input onChange={handleChange} type="radio" name="employmentType" id="Publico" value="1" />
                <label className="radioBtn" for="Publico">Público</label>
              </div>
              <div className="radioDiv" tabindex="2">
                <input onChange={handleChange} type="radio" name="employmentType" id="Privado" value="2" />
                <label className="radioBtn" for="Privado">Privado</label>
              </div>
            </div>
            <span className="invalid-feedback" id="employmentTypeHelpBlock">&nbsp;</span> 
          </div>



          <div className="anchor" id="housingTypeAnchor"></div>
          <div className="form-group col-12">
            <label className="largeLabel">Habitação</label>
            <div className="row pl-3 fourButtons">
              <div className="radioDiv" tabindex="6">
                <input type="radio" name="housingType" id="housingType1" value="rent" checked={selectedOption === 'rent'} onChange={handleOptionChange} />
                <label className="radioBtn" for="housingType1">Arrendada</label>
              </div>
              <div className="radioDiv" tabindex="6">
                <input type="radio" name="housingType" id="housingType2" value="family" checked={selectedOption === 'family'} onChange={handleOptionChange} />
                <label className="radioBtn" for="housingType2">Familiares</label>
              </div>
              <div className="radioDiv" tabindex="6">
                <input type="radio" name="housingType" id="housingType3" value="pch" checked={selectedOption === 'pch'} onChange={handleOptionChange} />
                <label className="radioBtn" for="housingType3">Própria com Crédito Habitação</label>
              </div>
              <div className="radioDiv" tabindex="6">
                <input type="radio" name="housingType" id="housingType4" value="psc" checked={selectedOption === 'psc'} onChange={handleOptionChange} />
                <label className="radioBtn" for="housingType4">Própria sem Crédito Habitação</label>
              </div>
            </div>
            <span className="invalid-feedback" id="housingTypeHelpBlock">&nbsp;</span>
          </div>

          {selectedOption === 'rent' && (
          <div className="form-group col-12" id="mortgageInfo">
            <div className="row">
              <div className="form-group col-md-6">
                <label id="rentLabel">Custo da renda</label>
                <input onChange={handleChange} className="form-control mortgageAmount" type="text" id="mortgageAmount" name="mortgageAmount" tabindex="7" />
                <span className="invalid-feedback">&nbsp;</span>
              </div>
              <div className="form-group col-md-6">
                <label>Desde Quando&nbsp;(Ano)</label>
                <div className="input-group mt-0 date" id="datetimepickerDtStart" data-target-input="nearest">
                  <input onChange={handleChange} className="form-control datetimepicker-input" data-target="#datetimepickerDtStart" type="date" data-mask="0000" id="dtStart" name="dtStart" placeholder="AAAA" tabindex="8" autocomplete="off" maxlength="4" />
                </div>
                <span className="invalid-feedback">&nbsp;</span>
              </div>
            </div>
          </div>
          )}

          {selectedOption === 'pch' && (
            <div className="form-group col-12" id="mortgageInfo">
              <div className="row">
                <div className="form-group col-md-6">
                  <label id="mortgageLabel">Prestação do Crédito</label>
                  <input onChange={handleChange} className="form-control mortgageAmount" type="text" id="mortgageAmount" name="mortgageAmount" tabindex="7" />
                  <span className="invalid-feedback">&nbsp;</span>
                </div>
                <div className="form-group col-md-6">
                  <label>Desde Quando&nbsp;(Ano)</label>
                  <div className="input-group mt-0 date" id="datetimepickerDtStart" data-target-input="nearest">
                    <input onChange={handleChange} className="form-control datetimepicker-input" data-target="#datetimepickerDtStart" type="date" data-mask="0000" id="dtStart" name="dtStart" placeholder="AAAA" tabindex="8" autocomplete="off" maxlength="4" />
                  </div>
                  <span className="invalid-feedback">&nbsp;</span>
                </div>
              </div>
            </div>
            )}
        </div>

      <div className="form-group col-12 px-0  d-block">
      <button className="generalInfoBtn align-items-center justify-content-center" onClick={handleNextStep}>Guardar e Avançar</button>
      </div>
      
    </div>
    </div>
  );
}

export default Step1;
