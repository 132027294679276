import React, { useState, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

import Container from 'react-bootstrap/Container';
import './index.css';
import './documentation.css';

  const FileUploadPage = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const leadToken = searchParams.get('token');
    console.log("LeadToken: " + leadToken);

    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
      };
      
      const focusedStyle = {
        borderColor: '#2196f3'
      };
      
      const acceptStyle = {
        borderColor: '#00e676'
      };
      
      const rejectStyle = {
        borderColor: '#ff1744'
      };

    const [fileList, setFileList] = useState([
        { name: 'Cartão de Cidadão', description: 'Frente e verso do seu Cartão de Cidadão. Pode enviar um lado de cada vez.', file: null, thumbnail: null, checked: false },
        { name: 'Comprovativo de Morada', description: 'A sua Certidão de Domícilio Fiscal, retirada no Portal das Finanças, ou uma fatura da água, eletricidade ou telecomunicações, por exemplo. Deve ter uma data inferior a 3 meses.', file: null, thumbnail: null, checked: false },
        { name: 'Último Mapa de Responsabilidades do Banco de Portugal disponível', description: '', file: null, thumbnail: null, checked: false },
        { name: '3 Últimos recibos de Vencimento', description: 'Uma cópia dos seus recibos de vencimento mais recentes. Pode enviar num só documento ou um de cada vez, a escolha é sua.', file: null, thumbnail: null, checked: false },
        { name: 'Comprovativo de IBAN', description: 'Pode pedir no Website/App do seu Banco ou no MULTIBANCO. O seu nome deve ser visível e a data do documento inferior a 3 meses.', file: null, thumbnail: null, checked: false },
        { name: 'Último Modelo 3 do IRS', description: 'Poderá encontrá-lo na sua área pessoal no Portal das Finanças. Deverá ser visível a primeira página completa do Modelo 3 do seu último IRS.', file: null, thumbnail: null, checked: false }
      ]); 

    const handleFileUpload = (acceptedFiles, index) => {
      const updatedFileList = acceptedFiles.map((file) => ({
        name: file.name,
        file: file,
        thumbnail: URL.createObjectURL(file),
        checked: true
      }));
  
      setFileList((prevFileList) => {
        const updatedList = [...prevFileList];
        updatedList.splice(index, 1, ...updatedFileList);
        return updatedList;
      });
    };
  
    const handleCheckboxChange = (index) => {
      setFileList((prevFileList) => {
        const updatedList = [...prevFileList];
        updatedList[index].checked = !updatedList[index].checked;
        return updatedList;
      });
    };
  
    const handleFileUploadAll = () => {
      fileList.forEach((fileData) => {
        // Perform upload logic for each file
        console.log(`Uploading file: ${fileData.name}`, fileData.file);
      });
    };

    const DropzoneItem = ({ fileData, index }) => {
      const { getRootProps, getInputProps, isDragActive, isFocused, isDragAccept, isDragReject } = useDropzone({
        onDrop: (acceptedFiles) => handleFileUpload(acceptedFiles, index),
        accept: {
            'image/*': [],
            ' application/pdf': []
          },
        multiple: true,
        maxSize: 20000000,
        maxFiles: 5
      });

      const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
      }), [
        isFocused,
        isDragAccept,
        isDragReject
      ]);
  
      return (
        <div {...getRootProps(style)} className={`dropzone ${isDragActive ? 'uploadBlock align-items-center d-flex' : 'uploadBlock align-items-center d-flex'}`}>
        <img className="uploadImg" src="https://client.gestlifes.com/webroot/IMAGE/JPCOM/ICON/upload_doc.svg" alt="Ícone" /> 

        <input {...getInputProps()} />
          {isDragActive ? (
            <span className='uploadText'>Largue aqui o seu documento..</span>
          ) : (
            <span className='uploadText'>Clique para carregar o seu documento</span>
          )}
        </div>
      );
    };
  
    return (
        <Container>
        <article className="App title">
            <span className="procTitle d-inline-flex align-items-center">
                <img className="docTitleIcon" src="https://client.gestlifes.com/webroot/IMAGE/JPCOM/ICON/docs-full.svg" alt="Ícone"></img>
                <h1 className="m-0">Documentação</h1>
            </span>

            <div className="card">
                <div className='card-body p-0'>
                    <div className='row'>
                        <div className='col-xl-4 pr-0 summaryState d-none d-xl-block'>
                            <div className='statusTitle'>Documentos Enviados</div>
                    
                        {fileList.map((fileData, index) => (
                            <div>
                            <div className='labelText' key={index}>
                                <img className="statusImg" src="https://client.gestlifes.com/webroot/IMAGE/JPCOM/ICON/docs_empty.svg" alt="Ícone" />
                                <label>
                                {fileData.name}
                                </label>
                            </div>
                            <div className='labelSpace'></div>
                            </div>
                            ))}
                            </div>

                    <div className="col-xl-7 mx-auto documentSection">
                      <div className="speedUp">
                          <div className="lamp d-flex align-items-center justify-content-center">
                              <img src="https://client.gestlifes.com/webroot/IMAGE/JPCOM/ICON/lamp.svg" alt="Ícone" />
                          </div>
                          <div className="speedText">
                              <span>Não tem tempo para carregar todos os documentos? Nós podemos ajudar.</span>
                          </div>
                          <button className="speedBtn" id="speedBtn" data-toggle="modal" data-target="#modalSpeedUpHolder">
                              <img src="https://client.gestlifes.com/webroot/IMAGE/JPCOM/ICON/cron_blue.svg" alt="Ícone" />
                              <span>Acelerar o Processo</span>
                          </button>
                      </div>

                        <div className="file-upload-container">
                        <h2>Documentos Exigidos</h2>
                          {fileList.map((fileData, index) => (
                              <div key={index} className="uploadDoc">
                                  <div className='statusTitle pl-3'>
                                      <span>{fileData.name}</span>
                                  </div>
                                  
                                  <div className="textDescription">
                                      <span>{fileData.description}</span>
                                  </div>
                              
                                  <DropzoneItem className='divUpload' fileData={fileData} index={index} />
                                  {fileData.thumbnail && (
                                      <img src={fileData.thumbnail} alt={fileData.name} className="thumbnail" />
                                  )}
                              </div>
                          ))}
                        <button className="generalInfoBtn align-items-center justify-content-center" onClick={handleFileUploadAll}>Submeter Documentos</button>
                        </div>
                    </div>
                  </div>
            </div>

            <div className="managerHelp d-none d-xl-flex align-items-center">
                <img className="photo" src="https://client.gestlifes.com/webroot/IMAGE/JPCOM/DASHBOARD/menina.png" alt="Imagem" />
                <div className="row mx-auto">
                    <div className="col-12 doubts">
                    <span>Tem dúvidas? Contacte-nos</span>
                    </div>
                    <div className="col-12">
                    <img className="helpImg" src="https://client.gestlifes.com/webroot/IMAGE/JPCOM/ICON/red_phone.svg" alt="Ícone" />
                    <a className="phone ml-1" href="tel:+351220931950">Telefone</a>
                    <img className="helpImg ml-2" src="https://client.gestlifes.com/webroot/IMAGE/JPCOM/ICON/whatsapp.svg" alt="Logotipo" />
                    <a className="whatsapp ml-1" href="https://api.whatsapp.com/send?phone=+351911744738">WhatsApp</a>
                    </div>
                </div>
                </div>
            </div>  
      </article>

      <p className='tokenID'><span>Token ID:</span> {leadToken}</p>
    </Container>
    );
  };
  
  export default FileUploadPage;