import React, { useState, useMemo, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { getLeadData } from './APIconfig'; // Replace this with your actual API function

import Container from 'react-bootstrap/Container';
import './index.css';
import './process.css';

const Process = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const leadToken = searchParams.get('token');
    console.log("LeadToken: " + leadToken);

    const { token } = useParams();
    const [leadData, setLeadData] = useState(
        {
            id: 1,
            status: '1',
            motive: 'O seu processo está a ser analisado. No prazo de 48h a 72h entraremos em contacto consigo. Por favor aguarde.',
            creditType: 'Automóvel',
            name: 'João Pereira',
            amount: '5.000,00',
            target: '48'
        }
    );

    useEffect(() => {
        // Fetch lead data from the API using the token from the URL
        getLeadData(token)
            .then((response) => {
            setLeadData(response.data); // Assuming the API response contains the lead data
        })
            .catch((error) => {
            console.error('Error fetching lead data:', error);
        });
    }, [token]);
    
    if (!leadData) {
        // Data is not yet available, you can return a loading state here
        return <div>Loading...</div>;
    }

    console.log(leadData);

    // Conditional rendering based on leadData
    let iconToShow;
    let labelToShow;
    if (leadData.status === '1') {
        //Em análise
        iconToShow = (
            <div>
                <img src="https://client.gestlifes.com/webroot/IMAGE/JPCOM/ICON/analysis.svg" alt="Em análise" />
            </div>
        );
        labelToShow = 'Em Análise';
    } else if (leadData.status === '2') {
        // Aprovado
        iconToShow = (
            <div>
                <img src="https://client.gestlifes.com/webroot/IMAGE/JPCOM/ICON/approved.svg" alt="Aprovado" />
            </div>
        );
        labelToShow = 'Aprovado';
    }  else if (leadData.status === '3') {
        // Recusado
        iconToShow = (
            <div>
            <img src="https://client.gestlifes.com/webroot/IMAGE/JPCOM/ICON/declined.svg" alt="Recusado" />
            </div>
        );
        labelToShow = 'Recusado';
    } else {
        // Default icon if the status is not matched
        iconToShow = (
            <div>
                <img src="https://client.gestlifes.com/webroot/IMAGE/JPCOM/ICON/default.svg" alt="Recusado" />
            </div>
        );
        labelToShow = 'Recusado';
    }

    return (
        <Container>
            <article className="App title">
                <span className="procTitle d-inline-flex align-items-center">
                    <img className="docTitleIcon" src="https://client.gestlifes.com/webroot/IMAGE/JPCOM/ICON/docs-full.svg" alt="Ícone"></img>
                    <h1 className="m-0">Estado do Processo</h1>
                </span>

                <div className="row stateTypeBlock">
                    <div className="col-6 col-md-6 stateTypeContainer mb-2 mb-md-0">
                        <div className="stateType d-flex flex-wrap align-items-center">
                            {iconToShow}
                            <div className="row middleText">
                                <span className="col-12 stateTitleText m-0 p-0">Estado</span>
                                <span className="col-12 statusDescription m-0 p-0">{labelToShow}</span>
                                <span className="col-12 underAnalysis m-0 p-0">{leadData.motive}</span>
                            </div>
                        <a className="buttonProcDoc flex-wrap align-items-center justify-content-center d-inline-flex" href="/process">
                            <span>Obter Pré-Aprovação incluindo um 2º titular</span>
                        </a>
                    </div>
                </div>

                <div className="col-6 col-md-6 stateTypeContainer mb-2 mb-md-0">
                    <div className="creditInfo d-flex align-items-center">
                        <div className="processInfoBlock">

                            <div className="upperInfo">
                                <div className="upperText">
                                    <span className="creditTypeTitle">Tipo de Crédito</span>
                                    <span className="creditTypeText">{leadData.creditType}</span>
                                </div>
                            </div>

                            <div className="upperInfo">
                                <div className="upperText">
                                    <span className="creditTypeTitle">Prazo:</span>
                                    <span className="creditTypeText">{leadData.target} meses</span>
                                </div>
                            </div>

                            <div className="upperInfo">
                                <div className="upperText">
                                    <span className="creditTypeTitle">Montante:</span>
                                    <span className="creditTypeText">{leadData.amount} €</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="align-items-center managerBlock mt-md-4 mx-0 d-none d-md-flex">
                    <div className="managerPhoto">
                        <div className="circle3">
                            <div className="circle2">
                                <div className="circle1">
                                    <img className="photoProcess" src="https://client.gestlifes.com/webroot/IMAGE/JPCOM/DASHBOARD/menina.png" alt="Imagem" />
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row ml-md-3 ml-xl-1">
                            <span className="col-5 managerTitle">Tem duvidas? Pergunte ao seu gestor!</span>
                            <span className="col-7 managerTitle d-flex justify-content-end align-items-center">Ou ligue para o&nbsp;seu gestor,&nbsp;das 9:30 até às 19:00</span>
                            <span className="col-6 managerName">Maria João</span>
                                <a className="col-6 telefonelink d-flex justify-content-end align-items-center" href="tel:+351220931950">+351 220 931 950
                                    <img className="ml-2" src="https://client.gestlifes.com/webroot/IMAGE/JPCOM/ICON/black_phone.svg" alt="Ícone" />
                                </a>
                                <a className="col-6 managerEmail" href="mailto:maria.joao@gestlifes.com">maria.joao@gestlifes.com</a>
                                <a className="col-6 whatsapp d-flex justify-content-end align-items-center" href="https://api.whatsapp.com/send?phone=+351911744738" target="__blank">WhatsApp
                                    <img className="ml-2" src="https://client.gestlifes.com/webroot/IMAGE/JPCOM/ICON/whatsapp.svg" alt="Logotipo" />
                                </a>
                            </div>
                        </div>
                    </div>
            </article>
            <p className='tokenID'><span>Token ID:</span> {leadToken}</p>
        </Container>
    );
};

export default Process;