import axios from 'axios';

const BASE_URL = 'https://api.jpcom.pt';
const SEND_EMAIL = 'https://api.jpcom.pt/api/send-email';
const CREDITCARD_LINK = 'https://cartoes.bankinterconsumerfinance.pt/cartao-gold/?utm_source=alts&utm_medium=m50&utm_campaign=gold_ao&utm_content=gestlifes';

export const getLeadData = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/process/${token}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching lead data:', error);
    throw error;
  }
};