import React from 'react';
// import { Header } from "./header"; 
import MultiStepForm from './GestlifesForm/Multistep';
import './index.css';
import './navbar.css';
import './footer.css';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

function App() {
  return (
    <Container>
      <article className="App title">
        <span className="procTitle d-inline-flex align-items-center">
            <img className="procTitleIcon" src="https://client.gestlifes.com/webroot/IMAGE/JPCOM/ICON/process-full.svg" alt="Ícone"></img>
            <h1 className="m-0">Pré-Aprovação de Crédito</h1>
        </span>

        <MultiStepForm className="col-12 mx-auto p-0" />

        <div className="managerHelp d-none d-xl-flex align-items-center">
          <img className="photo" src="https://client.gestlifes.com/webroot/IMAGE/JPCOM/DASHBOARD/menina.png" alt="Imagem" />
          <div className="row mx-auto">
            <div className="col-12 doubts">
              <span>Tem dúvidas? Contacte-nos</span>
            </div>
            <div className="col-12">
              <img className="helpImg" src="https://client.gestlifes.com/webroot/IMAGE/JPCOM/ICON/red_phone.svg" alt="Ícone" />
              <a className="phone ml-1" href="tel:+351220931950">Telefone</a>
              <img className="helpImg ml-2" src="https://client.gestlifes.com/webroot/IMAGE/JPCOM/ICON/whatsapp.svg" alt="Logotipo" />
              <a className="whatsapp ml-1" href="https://api.whatsapp.com/send?phone=+351911744738">WhatsApp</a>
            </div>
          </div>
        </div>

      </article>
    </Container>
  );
}

export default App;