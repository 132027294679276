import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './APIconfig';
import './index.css';
import './documentation.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import Documentation from './Documentation';
import Process from './Process';
import reportWebVitals from './reportWebVitals';

const initialValues = {
  clientName: '',
  clientEmail: '',
  clientPhone: '',
  clientNationality: '',
  clientBirthdate: '',
  clientNIF: '',
  clientCivilStatus: '',
  clientProfessionalStatus: '',
  clientProfessionalSince: '',
  clientIncome: '',
  clientEmployerType: '',
  clientResidenceType: '',
  clientHousingType: '',
  clientHousingSince: '',
  clientHousingRent: '',
  creditType:'',
  creditMotive: '',
  creditAmount: '',
  creditTime: '',
  consolidatedDebt: '',
  consolidatedExtra: '',
  consolidatedHousing: '',
};

function Index() {
  const [step, setStep] = useState(1);
  const [values, setValues] = useState(initialValues);

  function handleChange(event) {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  }

  function nextStep() {
    setStep(step + 1);
  }

  function prevStep() {
    setStep(step - 1);
  }

  function resetForm() {
    setStep(1);
    setValues(initialValues);
  }

  return (
    <Router>
    <Routes>
      <Route
        path="/"
        element={
          <App
            step={step}
            values={values}
            handleChange={handleChange}
            nextStep={nextStep}
            prevStep={prevStep}
            resetForm={resetForm}
          />
        }
      />
      <Route path="/documentos" element={<Documentation />} />
      <Route path="/estado-processo" element={<Process />} />
    </Routes>
  </Router>
  );
}

ReactDOM.render(<Index />, document.getElementById('root'));
reportWebVitals();
