import React from 'react';

function Step2(props) {
  const { formData, formStepsData, currentStep, setFormData, handlePreviousStep, handleChange, handleNextStep, selectedOption, setSelectedOption, handleOptionChange } = props;

   // Function to validate the form data
   const validateForm = () => {
    if (selectedOption === 'yes') {
      const { relationship, secHolderName, secHolderBirthdate, TINSecHolder, secHolderPhone, secHolderEmail, secHolderEmploymentContract, employmentContractDateSecHolder, incomeSecHolder, nationalitySecHolder, residenceTitleSecHolder, housingType2ndHolder, mortgageAmount2ndHolder, dtStart2ndHolder } = formStepsData[currentStep - 1].data;

      if (!relationship || !secHolderName || !secHolderBirthdate || !TINSecHolder || !secHolderPhone || !secHolderEmail || !secHolderEmploymentContract || !employmentContractDateSecHolder || !incomeSecHolder || !nationalitySecHolder || !residenceTitleSecHolder || !housingType2ndHolder) {
        // If any of the required fields are missing, show error messages
        const requiredFields = ['relationship', 'secHolderName', 'secHolderBirthdate', 'TINSecHolder', 'secHolderPhone', 'secHolderEmail', 'secHolderEmploymentContract', 'employmentContractDateSecHolder', 'incomeSecHolder', 'nationalitySecHolder', 'residenceTitleSecHolder', 'housingType2ndHolder'];
        requiredFields.forEach(field => {
          const inputElement = document.getElementById(field);
          const helpBlockElement = document.getElementById(`${field}HelpBlock`);
          if (!formStepsData[currentStep - 1].data[field]) {
            inputElement.classList.add('is-invalid');
            helpBlockElement.innerText = 'Este campo é obrigatório.';
          } else {
            inputElement.classList.remove('is-invalid');
            helpBlockElement.innerText = '';
          }
        });
        return false;
      }

      // Additional validation rules can be added here

    }

    return true;
  };

  // Function to handle form submission
  const handleSubmit = () => {
    if (validateForm()) {
      handleNextStep();
    }
  };

  return (
    <div>
      <h2>Segundo Titular</h2>
      <div className="form-group holderTip d-flex align-items-center col-12">
        <img className="justify-content-start mr-3" src="https://client.gestlifes.com/webroot/IMAGE/JPCOM/ICON/info.svg" alt="Ícone" />
        <span className="secondHolderDisclaimer justify-content-end">Sabia que ao incluir um segundo titular existe uma maior probabilidade do seu crédito ser aprovado? Pretende incluir o mesmo?</span>
      </div>

      <div className="row">
        <div className="form-group col-12">
          <label className="largeLabel">Quer incluir um segundo titular?</label>
          <div className="row pl-3 fourButtons" id="secHolderQuestion">
            <div className="radioDiv" disabled="" tabindex="9">
              <input type="radio" id="secHolderYes" name="secHolder" value="yes" checked={selectedOption === 'yes'} onChange={handleOptionChange} />
              <label className="radioBtn" for="secHolderYes">Sim</label>
            </div>
            <div className="radioDiv" disabled="" tabindex="10">
              <input type="radio" id="secHolderNo" name="secHolder" value="no" checked={selectedOption === 'no'} onChange={handleOptionChange}  />
              <label className="radioBtn" for="secHolderNo">Não</label>
            </div>
          </div>
        </div>
      </div>

      {selectedOption === 'yes' && (
        <div className="row" id="secHolderInfo">
          <div className="form-group col-12">
            <label>Relação</label>
            <div className="dropdown bootstrap-select col-12 p-0">
              <select className="selectpicker col-12 p-0" id="relationship" name="relationship" data-style="form-control" title="Selecione..." tabindex="11">
                <option className="bs-title-option" value=""></option>
                <option value="1" selected="">Cônjuge</option>
                <option value="2">Parceiro (União de Facto)</option>
                <option value="3">Pai / Mãe</option>
                <option value="4">Avó / Avô</option>
                <option value="5">Filho / Filha</option>
                <option value="6">Irmã / Irmão</option>
                <option value="7">Outra</option>
              </select>
            </div>
          </div>

          <div className="form-group col-12">
            <div className="row">
              <div className="form-group col-12">
                <label>Nome</label>
                <input className="form-control" id="secHolderName" name="secHolderName" tabindex="12" />
                <span className="invalid-feedback">&nbsp;</span>
              </div>
              <div className="form-group col-12 col-md-6">
              <label>Data De Nascimento</label>
              <div className="input-group mt-0 date" id="datetimepickerBirthdateSecHolder" data-target-input="nearest">
                <input className="form-control datetimepicker-input" data-target="#datetimepickerBirthdateSecHolder" type="date" data-mask="00/00/0000" id="secHolderBirthdate" name="secHolderBirthdate" placeholder="DD/MM/AAAA" tabindex="13" autocomplete="off" maxlength="10" />
              </div>
              <span className="invalid-feedback">&nbsp;</span>
            </div>
            <div className="form-group col-12 col-md-6">
              <label>NIF</label>
              <input className="form-control" id="TINSecHolder" name="TINSecHolder" placeholder="NIF do segundo titular" data-mask="000 000 000" tabindex="14" autocomplete="off" maxlength="11" />
              <span className="invalid-feedback">&nbsp;</span>
            </div>
            <div className="form-group col-12 col-md-6">
              <label>Telefone</label>
              <input className="form-control" id="secHolderPhone" name="secHolderPhone" type="text" data-mask="000 000 000" tabindex="15" autocomplete="off" maxlength="11" />
              <span className="invalid-feedback">&nbsp;</span>
            </div>
            <div className="form-group col-12 col-md-6">
              <label>E-mail</label>
              <input className="form-control" id="secHolderEmail" name="secHolderEmail" tabindex="16"/>
              <span className="invalid-feedback">&nbsp;</span>
            </div>
          </div>
        </div>


        <div className="form-group col-12">
          <label className="largeLabel">Situação Profissional</label>
          <div className="row pl-3">
            <div className="radioDiv" tabindex="17">
              <input type="radio" name="secHolderEmploymentContract" id="EfetivoSecHolder" value="1" />
              <label className="radioBtn" for="EfetivoSecHolder">Efetivo</label>
            </div>
          <div className="radioDiv" tabindex="17">
            <input type="radio" name="secHolderEmploymentContract" id="Termo CertoSecHolder" value="2" />
            <label className="radioBtn" for="Termo CertoSecHolder">Termo Certo</label>
          </div>
          <div className="radioDiv" tabindex="17">
            <input type="radio" name="secHolderEmploymentContract" id="IndependenteSecHolder" value="3" />
            <label className="radioBtn" for="IndependenteSecHolder">Independente</label>
          </div>
          <div className="radioDiv" tabindex="17">
            <input type="radio" name="secHolderEmploymentContract" id="TemporárioSecHolder" value="4" />
            <label className="radioBtn" for="TemporárioSecHolder">Temporário</label>
          </div>
          <div className="radioDiv" tabindex="17">
            <input type="radio" name="secHolderEmploymentContract" id="DesempregadoSecHolder" value="5" />
            <label className="radioBtn" for="DesempregadoSecHolder">Desempregado</label>
          </div>
          <div className="radioDiv" tabindex="17">
            <input type="radio" name="secHolderEmploymentContract" id="ReformadoSecHolder" value="6" />
            <label className="radioBtn" for="ReformadoSecHolder">Reformado</label>
          </div>
          <div className="radioDiv" tabindex="17">
            <input type="radio" name="secHolderEmploymentContract" id="OutroSecHolder" value="7" />
            <label className="radioBtn" for="OutroSecHolder">Outro</label>
          </div>
        </div>
        <span className="invalid-feedback" id="secHolderEmploymentContractHelpBlock">&nbsp;</span>
      </div>

      <div className="form-group col-12 col-md-6">
        <label>Desde Que Data&nbsp;(mês/Ano)</label>
        <div className="input-group mt-0 date" id="datetimepickerProfessionSecHolder" data-target-input="nearest">
          <input className="form-control datetimepicker-input" data-target="#datetimepickerProfessionSecHolder" type="date" data-mask="00/0000" id="employmentContractDateSecHolder" name="employmentContractDateSecHolder" placeholder="MM/AAAA" tabindex="18" autocomplete="off" maxlength="7" />
        </div>
        <span className="invalid-feedback" id="housingTypeLabel">&nbsp;</span>
      </div>


      <div className="form-group col-12 col-md-6">
        <label>Vencimento</label>
        <input className="form-control mortgageAmount" id="incomeSecHolder" name="incomeSecHolder" placeholder="Vencimento do 2º titular" tabindex="6" />
        <span className="invalid-feedback" >&nbsp;</span>
      </div>








  <div className="form-group col-md-6">
    <label>Nacionalidade</label>
    <div className="dropdown bootstrap-select col-12 p-0">
      <select className="selectpicker col-12 p-0" id="nationalitySecHolder" name="nationalitySecHolder" data-live-search="true" data-style="form-control" title="Selecione..." tabindex="7">
        <option option className="bs-title-option" value=""></option>
      </select>
    </div>
  <span className="invalid-feedback">&nbsp;</span></div>
  <div className="form-group col-md-6" id="residenceSecDiv">
    <label>Tipo de Residência</label>
      <div className="dropdown bootstrap-select col-12 p-0">
        <select className="selectpicker col-12 p-0" id="residenceTitleSecHolder" name="residenceTitleSecHolder" data-style="form-control" title="Selecione..." tabindex="8">
          <option className="bs-title-option" value=""></option>
          <option value="1" selected="">Residência Permanente</option>
          <option value="0">Residência Temporária</option>
        </select>
      </div>
      <span className="invalid-feedback">&nbsp;</span></div>


      <div className="form-group col-12">
        <label className="largeLabel">Reside na mesma habitação?</label>
        <div className="row pl-3 fourButtons" id="sameResidenceBlock">
          <div className="radioDiv" tabindex="19">
            <input type="radio" id="residenceTogether" name="residenceTogether" value="together" checked={selectedOption === 'together'} onChange={handleOptionChange}  />
            <label className="radioBtn" for="residenceTogether">Sim</label>
          </div>
        <div className="radioDiv" tabindex="20">
          <input type="radio" id="residenceSeparate" name="residenceSeparate" value="separate" checked={selectedOption === 'separate'} onChange={handleOptionChange}  />
          <label className="radioBtn" for="residenceSeparate">Não</label>
        </div>
      </div>
      </div>
      <div className="anchor" id="housingTypeAnchor2ndHolder"></div>

      {selectedOption === 'separate' && (
        <div className="form-group col-12">
          <label className="largeLabel">Habitação</label>
          <div className="row pl-3 fourButtons">
            <div className="radioDiv" tabindex="6">
              <input type="radio" name="housingType2ndHolder" id="housingType2ndHolder1" value="separate_rent" checked={selectedOption === 'separate_rent'} onChange={handleOptionChange} />
              <label className="radioBtn" for="housingType2ndHolder1">Arrendada</label>
            </div>
            <div className="radioDiv" tabindex="6">
              <input type="radio" name="housingType2ndHolder" id="housingType2ndHolder2" value="separate_family" checked={selectedOption === 'separate_family'} onChange={handleOptionChange} />
              <label className="radioBtn" for="housingType2ndHolder2">Familiares</label>
            </div>
            <div className="radioDiv" tabindex="6">
              <input type="radio" name="housingType2ndHolder" id="housingType2ndHolder3" value="separate_pch" checked={selectedOption === 'separate_pch'} onChange={handleOptionChange} />
              <label className="radioBtn" for="housingType2ndHolder3">Própria com Crédito Habitação</label>
            </div>
            <div className="radioDiv" tabindex="6">
              <input type="radio" name="housingType2ndHolder" id="housingType2ndHolder4" value="separate_psc" checked={selectedOption === 'separate_psc'} onChange={handleOptionChange} />
              <label className="radioBtn" for="housingType2ndHolder4">Própria sem Crédito Habitação</label>
            </div>
          </div>
          <span className="invalid-feedback" id="housingType2ndHolderHelpBlock">&nbsp;</span>
        </div>
      )}

      {selectedOption === 'separate_rent' && (
        <div className="form-group col-12" id="mortgageInfo">
          <div className="row">
            <div className="form-group col-md-6">
              <label id="rentLabel">Custo da renda</label>
              <input onChange={handleChange} className="form-control mortgageAmount" type="text" id="mortgageAmount2ndHolder" name="mortgageAmount2ndHolder" tabindex="7" />
              <span className="invalid-feedback">&nbsp;</span>
            </div>
            <div className="form-group col-md-6">
              <label>Desde Quando&nbsp;(Ano)</label>
              <div className="input-group mt-0 date" id="datetimepickerDtStart" data-target-input="nearest">
                <input onChange={handleChange} className="form-control datetimepicker-input" data-target="#datetimepickerDtStart" type="date" data-mask="0000" id="dtStart2ndHolder" name="dtStart2ndHolder" placeholder="AAAA" tabindex="8" autocomplete="off" maxlength="4" />
              </div>
              <span className="invalid-feedback">&nbsp;</span>
            </div>
          </div>
        </div>
      )}

      {selectedOption === 'separate_pch' && (
        <div className="form-group col-12" id="mortgageInfo">
          <div className="row">
            <div className="form-group col-md-6">
              <label id="mortgageLabel">Prestação do Crédito</label>
              <input onChange={handleChange} className="form-control mortgageAmount" type="text" id="mortgageAmount2ndHolder" name="mortgageAmount2ndHolder" tabindex="7" />
              <span className="invalid-feedback">&nbsp;</span>
            </div>
            <div className="form-group col-md-6">
              <label>Desde Quando&nbsp;(Ano)</label>
              <div className="input-group mt-0 date" id="datetimepickerDtStart" data-target-input="nearest">
                <input onChange={handleChange} className="form-control datetimepicker-input" data-target="#datetimepickerDtStart" type="date" data-mask="0000" id="dtStart2ndHolder" name="dtStart2ndHolder" placeholder="AAAA" tabindex="8" autocomplete="off" maxlength="4" />
              </div>
              <span className="invalid-feedback">&nbsp;</span>
            </div>
          </div>
        </div>
        )}

        </div>
      )}


      

      <div className="form-group col-12 px-0  d-block">
        <button className="generalBackBtn align-items-center justify-content-center" onClick={handlePreviousStep}>Voltar</button>

        <button className="generalInfoBtn align-items-center justify-content-center" onClick={handleNextStep}>Guardar e Avançar</button>
      </div>
    </div>
  );
}

export default Step2;
